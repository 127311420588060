import React, { useState } from 'react'
import editGreen from './../../../../src/imgs/EditGreen.png'
import settingGreen from './../../../../src/imgs/SettingGreen.png'
import 'react-toastify/dist/ReactToastify.css'
import ListPage from '../../Organisms/ListPage/ListPage'
import { getAllRole, getRoleById } from '../../../store/role/api'
import { CreateNewRolePopup } from '../../Organisms/Popups/CreateNewRolePopup/CreateNewRolePopup'
import { getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { FlowDetailRole } from './FlowDetailRole'

const Role = (props) => {
    const [render, setRender] = useState(false)
    const [flowDetail, setFlowDetail] = useState(null)
    const { id } = useParams()
    const roleTableColumn = [
        { headerName: 'Role Name', field: 'name', filter: true, sort: true },
        {
            headerName: 'Data Model',
            field: 'dataModelName',
            filter: true,
            sort: true,
        },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            // onClick={() => editFlowItem(params)}
                            src={editGreen}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={settingGreen}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                openFlowDetail(params)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                )
            },
        },
    ]
    const openFlowDetail = (params) => {
        getRoleById(params?.data?._id)
            .then((_flowDetail) => {
                setFlowDetail(_flowDetail)
            })
            .catch(handleError)
    }

    const closeFlowDetail = () => {
        setFlowDetail(null)
    }
    return (
        <>
            <ListPage
                idr={id}
                title="User Management"
                dataModelCall={getDataModel}
                dataCall={getAllRole}
                tableColumns={roleTableColumn}
                CreatePopupComponent={id === 'user' ? null : CreateNewRolePopup}
                btnName={id == 'user' ? '' : 'Create Role'}
                FlowPanel={FlowDetailRole}
                flowDetail={flowDetail}
                setFlowDetail={setFlowDetail}
                closeFlowDetail={closeFlowDetail}
                setOpenPanel={props.setOpenPanel}
                render={render}
            />
        </>
    )
}

export default Role
