import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'
// normalizeDataModels(response)
const normalizeCreatedDataModel = (response) => {
    if (response?.dataModel) {
        return {
            ...response?.dataModel,
            _id: response?.dataModel.modelId,
            createdBy: Users().getUserById(response?.dataModel.userId)?.name,
        }
    }
    return null
}
const normliezRoleById = (response) => {
    return {
        ...response.role,
        users: Users()?.USERS,
        permUsers: response?.users,
    }
}
const normalizeRoles = (response, dataModelsForNormalize) => {
    if (response?.roles?.length) {
        const data = response?.roles.map((item) => {
            return {
                ...item,
                createdBy: Users().getUserById(item.userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

export function getRoleById(roleId) {
    return Api()
        .get(`api/roles/${roleId}`)
        .then((response) => normliezRoleById(response))
}

export function createRole({ name, modelId }, dataModelsForNormalize) {
    return Api()
        .post(`api/roles/create`, {
            name: name,
            permissions: [],
            users: [],
        })
        .then((response) => {
            response.role._id = response.role.id
            response.role.dataModelName = dataModelsForNormalize.find(
                (dm) => dm.value === response.role.modelId,
            )?.name
            response.role.createdBy = Users().getUserById(
                response.role.userId,
            )?.name
            return response.role
        })
}

export function getAllRole(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/roles/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeRoles(response, dataModelsForNormalize))
}

export function updateRole(roleId, permissions) {
    return Api().put(`api/roles/update/${roleId}`, {
        permissions,
    })
}
