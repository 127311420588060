import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import s from './AddNewUserPopup.module.scss'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { toast } from 'react-toastify'
import { Api } from '../../../../Services/Api'

export const AddNewUserPopup = ({
    roleId,
    users = [],
    setAddShowPopup,
    addShowPopup,
    permUser,
    setPermUser,
}) => {
    const [selected, setSelected] = useState()
    useEffect(() => {
        if (users[0]) {
            setSelected(users[0].value)
        }
    }, [users])
    const addUser = (roleId,userId) =>{
        !permUser.find((i) => selected === i.value)
        ?   Api().put(`api/roles/update/${roleId}/add-user/${userId}`,{}).then((e)=>{
        toast.success(e?.msg)
    setPermUser([
            users.find((i) => selected === i.value),
            ...permUser,
        ])
        setAddShowPopup(false)
    }).catch(e=>console.error)
:toast.error('User exists')
    setAddShowPopup(false)
    }

    return addShowPopup && users?.length ? (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Add Users</div>
                    <div className={s.popupX}>
                        <img
                            onClick={() => setAddShowPopup(false)}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <SelectWithLabelInput
                            label="Users"
                            defaultValue={selected}
                            options={users}
                            onChange={(e) => {
                                setSelected(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={s.popupButtonsWrapper}>
                    <ButtonSmall
                        value="Add"
                        disabled={!selected}
                        onClick={() => addUser(roleId,selected)}
                    />
                </div>
            </div>
        </div>
    ) : null
}
