import React, { useEffect, useState } from 'react'
import { AddNewUserPopup } from '../../Organisms/Popups/AddNewUserPopup'
import { handleError } from '../../../utils/handleErrors'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonLighth } from '../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../Atoms/Buttons/ButtonSmall'
import CloseWrapper from '../../../utils/CloseWrapper'
import { updateRole } from '../../../store/role/api'
import Switch from './Switch'

import removed from './../../../imgs/remove.png'
import { Api } from '../../../Services/Api'

export const FlowDetailRole = ({ flowDetail, closeFlowDetail }) => {
  
    const [users, setUsers] = useState([])
    const [permUser, setPermUser] = useState([])
    const [addShowPopup, setAddShowPopup] = useState(false)
    const [permissions, setPermissions] = useState([])

    const PERMISSION_LIST = [
        'CREATE_SCRIPT',
        'EDIT_SCRIPT',
        'VIEW_SCRIPT',
        'CREATE_SEGMENT',
        'EDIT_SEGMENT',
        'VIEW_SEGMENT',
        'CREATE_SCORE',
        'EDIT_SCORE',
        'VIEW_SCORE',
        'CREATE_VALUE_SETTER',
        'EDIT_VALUE_SETTER',
        'VIEW_VALUE_SETTER',
        'CREATE_FLOW',
        'VIEW_FLOW',
        'EDIT_FLOW',
        'CREATE_DATA_STORAGE',
        'VIEW_DATA_STORAGE',
        'EDIT_DATA_STORAGE',
        'CREATE_INTEGRATION',
        'VIEW_INTEGRATION',
        'EDIT_INTEGRATION',
        'CREATE_INTEGRATION_DB',
        'VIEW_INTEGRATION_DB',
        'EDIT_INTEGRATION_DB',
        'FLOW_PUBLISH',
        'VIEW_PUBLISH_FLOW',
        'VIEW_LOGS',
        'VIEW_APIS',
        'CREATE_ROLE',
        'VIEW_ROLE',
        'EDIT_ROLE',
    ]
    useEffect(() => {
        setUsers(
            flowDetail?.users.map((i) => {
                return {
                    value: i?.id,
                    name: i.name,
                }
            }),
        )
        setPermissions(flowDetail?.permissions)
        setPermUser(
            flowDetail?.permUsers.map((i) => {
                return {
                    value: i?.id,
                    name: i.name,
                }
            }),
        )
    }, [flowDetail])
    const saveRole = (roleId, permissions) => {
        updateRole(roleId, permissions)
            .then(toast.success('Successfully Update'))
            .catch(handleError)
    }
    const handleToggle = (perm) => {
        if (permissions.includes(perm)) {
            setPermissions(permissions.filter((p) => p !== perm))
        } else {
            setPermissions([...permissions, perm])
        }
    }
    if (!flowDetail) {
        return null
    }
    
    const removeUser = (i,roleId) => {
        Api().put(`api/roles/update/${roleId}/remove-user/${i}`).then((res)=>{
            toast.success(res.msg)
            setPermUser(permUser.filter((rem) => i !== rem.value))
        }                
        ).catch(e=>console.error)
    }

    return (
        <>
            <CloseWrapper
                isOpen={flowDetail?.flow}
                onClose={closeFlowDetail}
            ></CloseWrapper>
            <div className="editWrapper">
                <div className="editHeader">
                    <div className="editTitle">Role Name Settings</div>
                    <div className="close" onClick={() => closeFlowDetail()}>
                        <img alt="x" src="/assets/images/settingsX.png" />
                    </div>
                </div>
                <div className="addBtnConTainer">
                    <div
                        className="apiLabelbuttonRole"
                        onClick={() => setAddShowPopup(true)}
                    >
                        Add
                    </div>
                </div>
                <div className="editBox">
                    <ul className="infoList">
                        {!permUser?.length ? (
                            <li className="infoListRole">
                                <div className="infoListNameRole">
                                    No Active User
                                </div>
                            </li>
                        ) : (
                            permUser?.map((i, index) => (
                                <li key={index} className="infoListRole">
                                    <div className="infoListNameRole">
                                        {i?.name}
                                    </div>
                                    <div className="infoListActions">
                                        <img
                                            src={removed}
                                            onClick={() => removeUser(i?.value,flowDetail?.id)}
                                        />
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                    <hr className="line" />
                    <div className="api-box">
                        <div className="searchPanel">
                            <h4>Permissions</h4>
                        </div>
                        <ul className="infoList">
                            {PERMISSION_LIST.map((perm, index) => {
                                return (
                                    <li className="infoListRole" key={index}>
                                        <div
                                            className="infoListNameRole"
                                            key={index}
                                        >
                                            {perm}
                                        </div>
                                        <div
                                            className="infoListActions"
                                            key={perm}
                                        >
                                            <Switch
                                                key={index}
                                                perm={perm}
                                                isOn={permissions?.includes(
                                                    perm,
                                                )}
                                                handleToggle={handleToggle}
                                                colorOne="#01AA6B"
                                                colorTwo="#292626"
                                            />
                                        </div>
                                    </li>
                                )
                            })}{' '}
                        </ul>
                    </div>
                </div>
                <div className="settingsActionRole">
                    <div className="rollBtns">
                        <div>
                            <ButtonLighth
                                onClick={() => closeFlowDetail()}
                                value="Cancel"
                            />
                        </div>
                        <div>
                            <ButtonSmall
                                value="Save"
                                onClick={() =>
                                    saveRole(
                                        flowDetail?.id,
                                        permissions,
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AddNewUserPopup
                roleId = {flowDetail?.id}
                setAddShowPopup={setAddShowPopup}
                permUser={permUser}
                setPermUser={setPermUser}
                users={users}
                addShowPopup={addShowPopup}
            />
        </>
    )
}
