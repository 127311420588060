import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'

const entityType = 'rule'
const entityURL = 'rules'

export const CreateRule = () => {
    const [entity, setEntity] = useState({
        name: '',
        description: '',
        key: '',
        group: 1,
        defaultValue: '',
    })
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="single-table-wrapper parameter-content">
                        <div className="inputsWrapper">
                            <div className="row">
                                <TextInput
                                    label="Name"
                                    value={entity.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                            </div>
                            <div className="row">
                                <label>
                                    Description
                                    <textarea
                                        value={entity.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                        }}
                                        rows="5"
                                        cols="25"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
