import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const ParameterListHeader = ({
    dataModel,
    setDataModel,
    dataModels = [],
}) => {
    const history = useHistory()
    const handleAdd = () => {
        history.push(`${window.location.pathname}/create/${dataModel}`)
    }
    useEffect(() => {
        if (dataModels?.length && !dataModel) {
            setDataModel(dataModels[0]?._id)
        }
    }, [dataModels?.length, dataModel])
    return (
        <div className="tableHeader">
            <div className="tableHeaderText">Parameters</div>
            {dataModels?.length ? (
                <div className="tableHeaderButton">
                    <SelectWithLabelInput
                        label=""
                        options={dataModels.map((item) => {
                            return { name: item.name, value: item._id }
                        })}
                        defaultValue={dataModel}
                        onChange={({ target }) => {
                            setDataModel(target.value)
                        }}
                    />
                    <ButtonWithIcon
                        onClick={handleAdd}
                        green={true}
                        icon="/assets/images/icon+.png"
                        value={'Add Rule'}
                    />
                </div>
            ) : null}
        </div>
    )
}
