import { Api } from '../../Services/Api'

export function login({ email, password }) {
    return Api().post('api/users/login', {
        email,
        password
    })
}

export function getAllUsers({ page, limit }) {
    return Api().get(`api/users/all?page=${page}&limit=${limit}`)
}

export function addUsers({ name, email, phone }) {
    return Api().post('api/users/create', {
        name,
        email,
        phone,
        clientId: 0
    })
}

export function updateUsers({ editName, password, phone, oldPassword }) {
    return Api().put('api/users/update', {
        name: editName,
        password,
        phone,
        oldPassword
    })
}