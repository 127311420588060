import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

const path = {
    script: 'scripts',
    segment: 'segments',
    score: 'scorings',
    scoring: 'scorings',
    valueSetter: 'value-setters',
    integration: 'integrations',
    integrationDB: 'integration-dbs',
    'data-model': 'data-models',
    parameter: 'parameters',
}

function normalize(response, type) {
    if (response[type]) {
        return response[type].map((item) => {
            return {
                value: item._id,
                name: item.models[0].name,
            }
        })
    }
    return []
}

export function getComponentsByDataModelId(type, modelId) {
    const withDataModel =
        type === 'integration' || type === 'integrationDb'
            ? ''
            : `/data-model/${modelId}`
    return Api()
        .get(`api/${path[type]}${withDataModel}`)
        .then((response) => normalize(response, path[type]))
}

export function archiveEntityById(id, type) {
    return Api().patch(`api/${path[type]}/archive/${id}`)
}

export function getEntityVersionById(id, type) {
    return Api()
        .get(`api/${path[type]}/${id}/versions`)
        .then((response) => {
            return response.body.map((v) => ({
                ...v,
                createdBy: Users().getUserById(v.userId).name,
            }))
        })
}

export function updateEntity(id, type, body) {
    return Api()
        .put(`api/${path[type]}/update/${id}`, body)
        .then((response) => {
            console.log('updateEntity')
            response[type]._id = response[type][`${type}Id`]
            response[type].createdBy = Users().getUserById(
                response[type].userId,
            ).name
            return response[type]
        })
}
