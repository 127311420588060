import React, { useState } from 'react'
import '../../Organisms/single-page-common/single-page-content-header.scss'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { createParameter } from '../../../store/parameter/api'
import { toast } from 'react-toastify'
import { handleError } from '../../../utils/handleErrors'
import { updateEntity } from '../../../store/common/api'
const entityType = 'parameter'

export const Header = ({ entity, updateInfo, entityURL = 'parameters' }) => {
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get('type') ?? null
    console.log(type)
    const [archivePopup, setArchivePopup] = useState(false)
    const [approvalPopup, setApprovalPopup] = useState(false)
    const cancel = () => {
        history.push(`/${entityURL}`)
        setArchivePopup(false)
    }
    const approve = () => {
        // TODO: need to call to appreove
        toast.success('Parameter send to check')
        setApprovalPopup(false)
    }
    const save = () => {
        createParameter({ ...entity, modelId: params.modelId })
            .then(() => {
                history.push(`/${entityURL}`)
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }
    const edit = () => {
        updateEntity(params.id, entityType, {
            name: entity.name,
            key: entity.key,
            defaultValue: entity.defaultValue,
            description: entity.description,
            group: entity.group,
        })
            .then(() => {
                updateInfo(params.id)
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }

    const handleSaveOrEdit = entity.id ? edit : save

    return (
        <>
            <ArchivePopup
                isOpen={archivePopup}
                togglePanel={setArchivePopup}
                onDelete={cancel}
                text="Are you sure you want to cancel"
            ></ArchivePopup>
            <ArchivePopup
                isOpen={approvalPopup}
                togglePanel={setApprovalPopup}
                onDelete={approve}
                text="Are you sure you want to sent to approval"
            ></ArchivePopup>
            <div className="single-page-content-header">
                <div className="title">
                    <span>{entity.name}</span>
                </div>
                {!type ? (
                    <div className="actions">
                        <ButtonWithIcon
                            onClick={() => setArchivePopup(true)}
                            icon="/assets/images/Delete.png"
                            red={true}
                            value="Cancel"
                        />
                        <ButtonWithIcon
                            onClick={handleSaveOrEdit}
                            value="Save"
                            green={true}
                        />
                        <ButtonWithIcon
                            onClick={() => setApprovalPopup(true)}
                            green={true}
                            value="Send To Approval"
                        />
                    </div>
                ) : null}
            </div>
        </>
    )
}
