import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { getEntityVersionById } from '../../../store/common/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import Table from '../../Organisms/Tables/Table/Table'
import { RulerHistoryChangesPopup } from './RulerHistoryChangesPopup'

const entityType = 'rule'
const entityURL = 'rules'

export const Rule = () => {
    const { id } = useParams()
    const [entity, setEntity] = useState({})
    const [versions, setVersions] = useState([])
    const [openRowPopup, setOpenRowPopup] = useState(null)
    const versionTableHeaders = [
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Approver',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <span
                        onClick={() => setOpenRowPopup(params.data.id)}
                        style={{ color: 'rgb(0 198 162 / 69%)' }}
                    >
                        View
                    </span>
                )
            },
        },
    ]

    const updateInfo = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setEntity(versions[0])
                }
            })
            .catch(handleError)
    }

    useEffect(() => {
        updateInfo(id)
    }, [])
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        updateInfo={updateInfo}
                        entity={entity}
                        entityURL={entityURL}
                    />
                    {openRowPopup ? (
                        <RulerHistoryChangesPopup
                            id={openRowPopup}
                            close={() => setOpenRowPopup(null)}
                        />
                    ) : null}
                    <div className="single-table-wrapper parameter-content parameter-individual-page">
                        <div className="inputsWrapper">
                            <div className="row">
                                <TextInput
                                    label="Name"
                                    value={entity.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                            </div>
                            <div className="row">
                                <label className="formInput">
                                    Description
                                    <textarea
                                        value={entity.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                        }}
                                        rows="5"
                                        cols="25"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="historyWrapper">
                            <div className="title">
                                <span>History</span>
                                <div className="wrapper">
                                    <Table
                                        column={versionTableHeaders}
                                        id={window.location.pathname}
                                        data={versions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
