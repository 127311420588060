import React, { useEffect, useMemo, useState } from 'react'
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    useReactFlow,
} from 'react-flow-renderer'
import s from './Flow.module.scss'
import { ScoreNode } from './Nodes/score'
import { ScriptNode } from './Nodes/script'
import { SegmentNode } from './Nodes/segment'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { ButtonText } from '../../Atoms/Buttons/ButtonText'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FlowArchivePopup } from '../../Organisms/Popups/FlowArchivePopup'
import { FlowEditComponentPopup } from '../../Organisms/Popups/FlowEditComponentPopup'
import { useHistory, useParams } from 'react-router-dom'
import { SidebarBlack } from '../../Organisms/SidebarBlack'
import { HeaderBlack } from '../../Organisms/HeaderBlack'
import { getSegmentById } from '../../../store/segment/api'
import {
    archiveFlowById,
    getFlowById,
    publishFlow,
    updateFlow,
} from '../../../store/flow/api'
import { handleError } from '../../../utils/handleErrors'

import { ValueSetterNode } from './Nodes/valueSetter'
import { IntegrationNode } from './Nodes/integration'
import CloseWrapper from '../../../utils/CloseWrapper'

export const Flow = (props) => {
    const params = useParams()
    const history = useHistory()
    const [flow, setFlow] = useState(null)
    const nodeTypes = useMemo(
        () => ({
            integration: IntegrationNode,
            integrationDb: IntegrationNode,
            script: ScriptNode,
            score: ScoreNode,
            segment: SegmentNode,
            valueSetter: ValueSetterNode,
        }),
        [],
    )

    useEffect(() => {
        if (params.id) {
            getFlowById(params.id)
                .then((_flow) => {
                    setFlow(_flow)
                })
                .catch(handleError)
        }
    }, [params.id])

    const updateFlowNodes = (nodes) => {
        setFlow({
            ...flow,
            nodes,
        })
    }
    const updateFlowEdges = (edges) => {
        setFlow({
            ...flow,
            edges,
        })
    }

    const onNodesChange = (changes) => {
        const cantDelete = changes.find((item) => {
            return (
                item.type === 'remove' && (item.id === '1' || item.id === '2')
            )
        })
        if (cantDelete) {
            return
        }
        updateFlowNodes(applyNodeChanges(changes, flow.nodes))
    }

    const onEdgesChange = (changes) => {
        updateFlowEdges(applyEdgeChanges(changes, flow.edges))
    }

    const onConnect = (connection) => {
        const node = flow.nodes.find((item) => item.id === connection.target)
        const isInValidTarget =
            node.type !== 'script' &&
            node.type !== 'output' &&
            flow.edges.find((item) => item.target === connection.target)
        const isInValidSource = flow.edges.find(
            (item) =>
                item.source === connection.source &&
                item.sourceHandle === connection.sourceHandle,
        )
        const isOutput = flow.nodes.find(
            (item) => item.id === connection.target,
        ).type
        if (isOutput === 'output' && (isInValidTarget || isInValidSource)) {
            return
        }
        const isSegment = flow.nodes.find(
            (item) => item.id === connection.source,
        )
        if (isSegment.type === 'segment') {
            connection.label = isSegment.data.children.find(
                (item) => item.sourceHandle === connection.sourceHandle,
            ).label
        }
        updateFlowEdges(addEdge(connection, flow.edges))
    }

    const onUpdateFlow = () => {
        const edges = flow.edges.filter((e) => {
            const hasTarget = flow.nodes.find((n) => n.id === e.target)
            const hasSource = flow.nodes.find((n) => n.id === e.source)
            return hasTarget && hasSource
        })
        const updatedFlow = {
            ...flow,
            body: { nodes: flow.nodes, edges },
            id: flow.flowId,
        }
        updateFlow(updatedFlow)
            .then((updateFlow) => {
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }

    const onArchiveFlow = () => {
        archiveFlowById(params.id)
            .then(() => {
                setFlowArchivePopup(false)
                history.push(`/flows`)
            })
            .catch(handleError)
    }

    const onPublish = () => {
        publishFlow(flow.flowId)
            .then(() => {
                toast.success('Flow is Published')
            })
            .catch(handleError)
    }

    const addComponent = (type) => {
        props.setOpenPanel(!props.openPanel)
        const id = `${new Date().getTime()}`
        const nodes = [
            ...flow.nodes,
            {
                id,
                type,
                data: {
                    label: `New ${type} ${flow.nodes.length + 1}`,
                    children: [],
                },
                position: { x: 250, y: 250 },
            },
        ]
        updateFlowNodes(nodes)
        RFI.setNodes(nodes)
    }
    const RFI = useReactFlow()

    const [flowArchivePopup, setFlowArchivePopup] = useState(false)
    const [editPopup, setEditPopup] = useState(null)

    const deleteNode = () => {
        const nodes = flow.nodes.filter((item) => item.id !== editPopup.nodeId)
        updateFlowNodes(
            flow.nodes.filter((item) => item.id !== editPopup.nodeId),
        )
        const edges = flow.edges.filter(
            (item) =>
                !(
                    item.target === editPopup.nodeId ||
                    item.source === editPopup.nodeId
                ),
        )
        updateFlowEdges(edges)
        RFI.setEdges(edges)
        RFI.setNodes(nodes)
        setEditPopup(null)
    }

    const onNodeClick = (_, node) => {
        if (node.type === 'input' || node.type === 'output') {
            return
        }
        setEditPopup({
            id: node.data.s_id,
            nodeId: node.id,
            type: node.type,
        })
    }
    const prepareAdditionalDataForSegment = async (id) => {
        const additionalData = {}
        if (editPopup.type === 'segment') {
            const { segment } = await getSegmentById({
                id,
            })
            additionalData.children = segment.conditions.map((item) => {
                return {
                    label: item.name,
                    id: editPopup.nodeId,
                    target: undefined,
                    sourceHandle: item.id,
                }
            })
        }
        return additionalData
    }

    const updateComponent = (data) => {
        updateFlowEdges(
            flow.edges.filter((item) => {
                return !(
                    item.target === editPopup.nodeId ||
                    item.source === editPopup.nodeId
                )
            }),
        )
        const newNodes = [
            ...flow.nodes.map(async (item) => {
                if (item.id === editPopup.nodeId) {
                    const additionalData =
                        await prepareAdditionalDataForSegment(data.id)

                    return {
                        ...item,
                        data: {
                            ...item.data,
                            label: data.name,
                            s_id: data.id,
                            ...additionalData,
                        },
                    }
                }
                return item
            }),
        ]
        Promise.all(newNodes).then((_newNodes) => {
            updateFlowNodes(_newNodes)
            RFI.setNodes(_newNodes)
            setEditPopup(null)
        })
    }

    return (
        <div className={s.mainWrapper}>
            <ToastContainer />
            <HeaderBlack />
            {flowArchivePopup && (
                <FlowArchivePopup
                    setFlowArchivePopup={setFlowArchivePopup}
                    dataName={flow?.name}
                    onArchiveData={onArchiveFlow}
                />
            )}
            <FlowEditComponentPopup
                config={editPopup}
                closePopup={() => setEditPopup(null)}
                deleteComponent={deleteNode}
                onChange={updateComponent}
                modelId={flow?.modelId}
            />
            <div className={s.contentWrapper}>
                <SidebarBlack activeItem="flow" />
                <div className={s.rightSide}>
                    <CloseWrapper
                        openPanel={props.openPanel}
                        setOpenPanel={props.setOpenPanel}
                    ></CloseWrapper>
                    {flow && (
                        <>
                            <div className={s.codeHeader}>
                                <div className={s.codeHeaderNav}>
                                    <div className={s.codeHeaderTitle}>
                                        {flow.name}
                                    </div>
                                    <ButtonWithIcon
                                        onClick={onPublish}
                                        value="Publish"
                                    />
                                </div>
                                <div className={s.codeHeaderActions}>
                                    <div className={s.apiPanelWrapper}>
                                        <img
                                            onClick={() =>
                                                props.setOpenPanel(
                                                    !props.openPanel,
                                                )
                                            }
                                            className={s.addIcon}
                                            src="/assets/images/plus.png"
                                        />
                                        {props.openPanel ? (
                                            <div className={s.panel}>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent('segment')
                                                    }
                                                >
                                                    Segment
                                                </div>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent('score')
                                                    }
                                                >
                                                    Score
                                                </div>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent(
                                                            'valueSetter',
                                                        )
                                                    }
                                                >
                                                    Value Setter
                                                </div>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent('script')
                                                    }
                                                >
                                                    Script
                                                </div>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent(
                                                            'integration',
                                                        )
                                                    }
                                                >
                                                    Integration
                                                </div>
                                                <div
                                                    className={s.api}
                                                    onClick={() =>
                                                        addComponent(
                                                            'integrationDb',
                                                        )
                                                    }
                                                >
                                                    integrationDB
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <ButtonText
                                        value="Delete"
                                        onClick={() =>
                                            setFlowArchivePopup(true)
                                        }
                                    />
                                    <ButtonWithIcon
                                        onClick={onUpdateFlow}
                                        value="Update"
                                    />
                                </div>
                            </div>
                            <div className={s.folwWrapper}>
                                <div className={s.folwWrapperDiv}></div>
                                <ReactFlow
                                    nodes={flow.nodes}
                                    edges={flow.edges}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onConnect={onConnect}
                                    onNodeClick={onNodeClick}
                                    fitView
                                    nodeTypes={nodeTypes}
                                >
                                    <Background />
                                    <Controls />
                                </ReactFlow>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
