import React, { useCallback, useEffect, useState } from 'react'
import user from './../../../imgs/users2.png'
import dataStorage from './../../../imgs/storageIcon.png'
import { getRoleById } from '../../../store/role/api'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

export const SidebarBlack = ({ activeItem = '' }) => {
    const [viewList, setViewList] = useState([])
    const roleId = JSON.parse(localStorage.getItem('User'))?.role

    let history = useHistory()
    const logout = useCallback(() => {
        history.push('/main')
        window.localStorage.clear()
    }, [history])

    useEffect(() => {
        getRoleById(roleId)
            .then((res) => setViewList(res?.permissions))
            .catch((e) => toast.error(e.msg))
    }, [])

    return (
        <div className="sidebarWrapper">
            <div className="sidebar">
                <div className="sidebarMenu">
                    <ul>
                        {viewList?.includes('VIEW_FLOW') ? (
                            <li
                                className={
                                    activeItem === 'flow' ? 'active' : ''
                                }
                            >
                                <Link to="/flows">
                                    <img
                                        src="/assets/images/s1.png"
                                        alt="Icon"
                                    />
                                    <span>Decision Tree</span>
                                </Link>
                            </li>
                        ) : null}
                        <li className={activeItem === 'data' ? 'active' : ''}>
                            <Link to="/data-models">
                                <img src="/assets/images/s2.png" alt="Icon" />
                                <span>Data Model</span>
                            </Link>
                        </li>
                        {/* <li className={activeItem === "datav2" ? 'active' : ""}>
                        <Link to="/data-modelsv2">
                            <img src="/assets/images/s2.png" alt="Icon" />
                            <span>Data Model v2</span>
                        </Link>
                    </li> */}

                        {viewList?.includes('VIEW_SCRIPT') ? (
                            <li
                                className={
                                    activeItem === 'script' ? 'active' : ''
                                }
                            >
                                <Link to="/scripts">
                                    <img
                                        src="/assets/images/s4.png"
                                        alt="Icon"
                                    />
                                    <span>Script</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_SEGMENT') ? (
                            <li
                                className={
                                    activeItem === 'segment' ? 'active' : ''
                                }
                            >
                                <Link to="/segments">
                                    <img
                                        src="/assets/images/s3.png"
                                        alt="Icon"
                                    />
                                    <span>Segment</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_SCORE') ? (
                            <li
                                className={
                                    activeItem === 'score' ? 'active' : ''
                                }
                            >
                                <Link to="/scores">
                                    <img
                                        src="/assets/images/Applications.png"
                                        alt="Icon"
                                    />
                                    <span>Score</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_PARAMETER') ? (
                            <li
                                className={
                                    activeItem === 'parameter' ? 'active' : ''
                                }
                            >
                                <Link to="/parameters">
                                    <img
                                        src="/assets/images/Applications.png"
                                        alt="Icon"
                                    />
                                    <span>Parameters</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_RULE') ? (
                            <li
                                className={
                                    activeItem === 'rule' ? 'active' : ''
                                }
                            >
                                <Link to="/rules">
                                    <img
                                        src="/assets/images/Applications.png"
                                        alt="Icon"
                                    />
                                    <span>Rules</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_VALUE_SETTER') ? (
                            <li
                                className={
                                    activeItem === 'valueSetter' ? 'active' : ''
                                }
                            >
                                <Link to="/value-setters">
                                    <img
                                        src="/assets/images/Applications.png"
                                        alt="Icon"
                                    />
                                    <span>Value Setter</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_INTEGRATION') ? (
                            <li
                                className={
                                    activeItem === 'integration' ? 'active' : ''
                                }
                            >
                                <Link to="/integrations">
                                    <img
                                        src="/assets/images/integration.png"
                                        width={30}
                                        alt="Icon"
                                    />
                                    <span>Integration</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_INTEGRATION_DB') ? (
                            <li
                                className={
                                    activeItem === 'integrationDB'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to="/integrationDBs">
                                    <img
                                        src="/assets/images/integration.png"
                                        width={30}
                                        alt="Icon"
                                    />
                                    <span>Integration DB</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_APIS') ? (
                            <li
                                className={
                                    activeItem === 'publishedFlow'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to="/published-flows">
                                    <img
                                        src="/assets/images/api.png"
                                        alt="Icon"
                                    />
                                    <span>Active APIs</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_LOGS') ? (
                            <li
                                className={
                                    activeItem === 'logs' ? 'active' : ''
                                }
                            >
                                <Link to="/logs">
                                    <img
                                        src="/assets/images/log.png"
                                        alt="Icon"
                                    />
                                    <span>Activity Log</span>
                                </Link>
                            </li>
                        ) : null}
                        {viewList?.includes('VIEW_ROLE') ? (
                            <li
                                className={
                                    activeItem === 'role' ? 'active' : ''
                                }
                            >
                                <Link to="/permesionmanagment/user">
                                    <img src={user} alt="Icon" />
                                    <span>Role Management</span>
                                </Link>
                            </li>
                        ) : null}
                        <li
                            className={activeItem === 'storage' ? 'active' : ''}
                        >
                            <Link to="/storage">
                                <img src={dataStorage} alt="Icon" />
                                <span>Data Storage</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <ul>
                        <li onClick={logout}>
                            <a onClick={(e) => e.preventDefault()}>
                                <img
                                    src="/assets/images/Logout.png"
                                    alt="Icon"
                                />
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
