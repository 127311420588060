import React, { useState } from 'react'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { TextInput } from '../../../Atoms/Inputs/Text'
import { createRole } from '../../../../store/role/api'
import s from './CreateNewRolePopup.module.scss'

export const CreateNewRolePopup = ({
    isOpen,
    closePopUp,
    setState,
    dataModelsList,
    setShowPopup,
    state,
}) => {
    const [roleName, setRoleName] = useState()
    const createRoles = ({ roleName, dataModel }, dataModelsList) => {
        setShowPopup(false)
        createRole(
            {
                name: roleName,
            },
            dataModelsList,
        ).then((createRole) => {
            setState([createRole, ...state])
        })
    }
    return (
        <>
            {isOpen && (
                <div className={s.popupWrapper}>
                    <div className={s.popupBox}>
                        <div className={s.popupHeader}>
                            <div className={s.popupName}>Create New Role</div>
                            <div className={s.popupX}>
                                <img
                                    onClick={closePopUp}
                                    src="/assets/images/popupX.png"
                                    alt="close"
                                />
                            </div>
                        </div>

                        <div className={s.popupForm}>
                            <div className={s.popupFormItem}>
                                <TextInput
                                    placeholder="Role Name"
                                    value={roleName}
                                    onChange={({ target }) => {
                                        setRoleName(target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className={s.popupButtonsWrapper}>
                            <ButtonLighth onClick={closePopUp} value="Cancel" />
                            <ButtonSmall
                                onClick={() => {
                                    createRoles(
                                        {
                                            roleName,
                                        },
                                        dataModelsList,
                                    )
                                }}
                                value="Create"
                                disabled={!roleName}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
