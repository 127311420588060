import React, { useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import PublicWrapper from './wrapers/PublicWrapper'
import './Styles/single-page.scss'
import './Styles/list-Page.scss'
import './Styles/side-bar.scss'
import './Styles/header-black.scss'
import './Styles/pop-up.scss'
import './App.css'

import AuthWrapper from './wrapers/AuthWrapper'
import { DataModel } from './Components/Pages/data-model'
import { Flow } from './Components/Pages/Flow'
import { Login } from './Components/Pages/Login'
import { Profile } from './Components/Pages/Profile'
import { UserManagement } from './Components/Pages/UserManagement'
import { Users } from './Services/users'
import { ReactFlowProvider } from 'react-flow-renderer'
import { Script } from './Components/Pages/script'
import { Segment } from './Components/Pages/segment'
import { FlowList } from './Components/Pages/FlowList'
import { ScriptList } from './Components/Pages/ScriptList'
import { SegmentList } from './Components/Pages/SegmentList'
import { DataModelList } from './Components/Pages/DataModelList'
import { PublishedList } from './Components/Pages/PublishedList'
import { LogsList } from './Components/Pages/LogsList'
import { Score } from './Components/Pages/score'
import { ScoreList } from './Components/Pages/ScoreList'
import { ValueSetter } from './Components/Pages/value-setter'
import { ValueSetterList } from './Components/Pages/ValueSetterList'
import { Integration } from './Components/Pages/integration'
import { IntegrationList } from './Components/Pages/IntegrationList'
import { IntegrationDb } from './Components/Pages/Integration-db'
import { IntegrationDBList } from './Components/Pages/IntegrationDBList'
import Role from './Components/Pages/role-pages/Role'
import Error from './utils/Error'
import { DataStore } from './Components/Pages/StorageLists/DataStore'
import { CreateDataStore } from './Components/Pages/CreateStorage/CreateDataStorage'
import { TableName } from './Components/Pages/Table/TableName'
import { DataStoreName } from './Components/Pages/DataStoreName/DataStoreName'
import { UpdateDataStorage } from './Components/Pages/CreateStorage/UpdateDataStorage'
import { ParameterList } from './Components/Pages/ParameterList'
import { CreateParameter } from './Components/Pages/parameter'
import { Parameter } from './Components/Pages/parameter/parameter'
import { CreateRule } from './Components/Pages/Rule'
import { Rule } from './Components/Pages/Rule/Rule'
import { RuleList } from './Components/Pages/RuleList'

function App() {
    const [openPanel, setOpenPanel] = useState(false)

    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to={'/main'} />}
                    />

                    <Route
                        path="/data-models/:id"
                        render={() => (
                            <AuthWrapper id="data">
                                <DataModel />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/data-models"
                        render={() => (
                            <AuthWrapper id="data">
                                <DataModelList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/flows/:id"
                        render={() => (
                            <ReactFlowProvider>
                                <Flow
                                    openPanel={openPanel}
                                    setOpenPanel={setOpenPanel}
                                />
                            </ReactFlowProvider>
                        )}
                    />

                    <Route
                        path="/flows"
                        render={() => (
                            <AuthWrapper id="flow">
                                <FlowList
                                    openPanel={openPanel}
                                    setOpenPanel={setOpenPanel}
                                />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/scripts/:id"
                        render={() => (
                            <AuthWrapper id="script">
                                <Script />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/scripts"
                        render={() => (
                            <AuthWrapper id="script">
                                <ScriptList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/main"
                        render={() => (
                            <PublicWrapper>
                                <Login />
                            </PublicWrapper>
                        )}
                    />

                    <Route
                        path="/user-management"
                        render={() => <UserManagement />}
                    />

                    <Route path="/profile" render={() => <Profile />} />

                    <Route
                        path="/scores/:id"
                        render={() => (
                            <AuthWrapper id="score">
                                <Score />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/scores"
                        render={() => (
                            <AuthWrapper id="score">
                                <ScoreList />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/rules/create/:modelId"
                        render={() => (
                            <AuthWrapper id="rule">
                                <CreateRule />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/rules/:id"
                        render={() => (
                            <AuthWrapper id="rule">
                                <Rule />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/rules"
                        render={() => (
                            <AuthWrapper id="rule">
                                <RuleList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/value-setters/:id"
                        render={() => (
                            <AuthWrapper id="valueSetter">
                                {' '}
                                <ValueSetter />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/value-setters"
                        render={() => (
                            <AuthWrapper id="valueSetter">
                                {' '}
                                <ValueSetterList />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/parameters/create/:modelId"
                        render={() => (
                            <AuthWrapper id="parameter">
                                <CreateParameter />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/parameters/:id"
                        render={() => (
                            <AuthWrapper id="parameter">
                                <Parameter />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/parameters"
                        render={() => (
                            <AuthWrapper id="parameter">
                                <ParameterList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/integrations/:id"
                        render={() => (
                            <AuthWrapper id="integration">
                                <Integration />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/integrations"
                        render={() => (
                            <AuthWrapper id="integration">
                                <IntegrationList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/integrationDBs/:id"
                        render={() => (
                            <AuthWrapper id="integrationDB">
                                <IntegrationDb />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/integrationDBs"
                        render={() => (
                            <AuthWrapper id="integrationDB">
                                <IntegrationDBList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/segments/:id"
                        render={() => (
                            <AuthWrapper id="segment">
                                <Segment />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/segments"
                        render={() => (
                            <AuthWrapper id="segment">
                                <SegmentList />
                            </AuthWrapper>
                        )}
                    />

                    <Route path="/users" render={() => <Users />} />

                    <Route
                        path="/logs"
                        render={() => (
                            <AuthWrapper id="logs">
                                {' '}
                                <LogsList />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/permesionmanagment/:id"
                        render={() => (
                            <AuthWrapper id="role">
                                <Role />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/update/:id"
                        render={() => (
                            <AuthWrapper id="storage">
                                <UpdateDataStorage />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/createstorage/:id"
                        render={() => (
                            <AuthWrapper id="storage">
                                <CreateDataStore />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/tableName"
                        render={() => (
                            <AuthWrapper id="storage">
                                <TableName />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/storage/:id"
                        render={() => (
                            <AuthWrapper id="storage">
                                <DataStoreName />
                            </AuthWrapper>
                        )}
                    />
                    <Route
                        path="/storage"
                        render={() => (
                            <AuthWrapper id="storage">
                                <DataStore />
                            </AuthWrapper>
                        )}
                    />

                    <Route
                        path="/published-flows"
                        render={() => (
                            <AuthWrapper id="publishedFlow">
                                <PublishedList />
                            </AuthWrapper>
                        )}
                    />

                    <Route path="*" render={() => <Error />} />
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default App
